<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="form-group mb-0">
              <vSelect
                @input="onOwnerSelect"
                class="form-control form-control-solid form-control--owner-select"
                placeholder="Select owner"
                :options="owners"
                :reduce="(owner) => owner['name']"
                label="name"
              >
                <template #option="{ name }">
                  <span class="mr-3">{{ name }}</span>
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isCardsLoaded" class="col-md-8">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              style="width: 100%"
              locale="hr"
              v-model="range"
              is-range
              :step="1"
              :columns="$screens({ sm: 1, md: 2, lg: 3, xl: 3 })"
              :attributes="attributes"
              :masks="masks"
              is24hr
              is-expanded
              @input="onRangeSelect"
              :max-date="new Date()"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isTransactionsLoaded" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Transactions</h3>
            </div>
            <div class="card-toolbar">
              <button
                @click.prevent="exportAsExcel"
                class="btn btn-light-primary font-weight-bold px-10"
              >
                Export .xlsx
              </button>
            </div>
          </div>
          <div class="card-body">
            <table
              class="table table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
                <tr class="text-uppercase">
                  <th class="pl-6"><span>Serial number</span></th>
                  <th><span>Full Name</span></th>
                  <th><span>Transactions</span></th>
                  <th><span>Total Amount</span></th>
                  <th><span>Total Bonus</span></th>
                  <th class="text-right pr-6">
                    <span>{{ $t('GENERAL.ACTIONS') }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(card, key) in cards" :key="key">
                  <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                    <span
                      v-if="card.serialNumber"
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                      >{{ card.serialNumber }}</span
                    >
                  </td>
                  <td :class="{ 'border-top-0': key === 0 }">
                    <span v-if="card.userFullName">{{
                      card.userFullName
                    }}</span>
                  </td>
                  <td :class="{ 'border-top-0': key === 0 }">
                    <span class="badge badge-primary">{{
                      card.transactions ? card.transactions.length : 0
                    }}</span>
                  </td>
                  <td :class="{ 'border-top-0': key === 0 }">
                    <span v-if="card.totalAmount > 0">{{
                      card.totalAmount | currencyFormat
                    }}</span>
                    <span v-else>0,00 €</span>
                  </td>
                  <td :class="{ 'border-top-0': key === 0 }">
                    <span v-if="card.totalBonus > 0">{{
                      card.totalBonus | currencyFormat
                    }}</span>
                    <span v-else>0,00 €</span>
                  </td>
                  <td
                    class="text-right pr-6"
                    :class="{ 'border-top-0': key === 0 }"
                  >
                    <span>
                      <button
                        @click="toggleCardsTransactionsModal(key)"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Text/Align-justify.svg"
                          />
                        </span>
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
            >
              <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="pagination.totalPages"
                use-router
                size="lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="card_transactions_modal"
          size="xl"
          hide-header
          hide-footer
          centered
        >
          <div>
            <button
              class="btn btn-light-primary btn-block mb-3"
              @click="$bvModal.hide('card_transactions_modal')"
            >
              Close
            </button>
            <div style="max-height: 700px; overflow: scroll">
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th class="pl-6">
                      <span>{{ $t('GENERAL.DEVICE') }}</span>
                    </th>
                    <th>
                      <span>{{ $t('GENERAL.LOCATION') }}</span>
                    </th>
                    <th><span>Imei</span></th>
                    <th>
                      <span>{{ $t('GENERAL.AMOUNT') }}</span>
                    </th>
                    <th>
                      <span>BONUS</span>
                    </th>
                    <th class="text-right pr-6"><span>Date & Time</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, key) in cardTransactions" :key="key">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ transaction.deviceName }}</span
                      >
                      <div>
                        <span
                          class="label label-sm label-light-primary label-inline mr-2"
                          >{{ transaction.tags }}</span
                        >
                      </div>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{ transaction.locationName }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{ transaction.imei }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span class="font-weight-bold">{{
                        parseFloat(transaction.amount) | currencyFormat
                      }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span class="font-weight-bold">{{
                        parseFloat(transaction.bonus) | currencyFormat
                      }}</span>
                    </td>
                    <td
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{ transaction.createdAt | formatDateTime }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import vSelect from 'vue-select'
import ApiService from '../../../../core/services/api.service'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { format } from 'date-fns'
import addDays from 'date-fns/addDays'

export default {
  name: 'LoyaltyOwnersTransactions',
  components: {
    vSelect,
    DatePicker,
  },
  data() {
    return {
      owners: [],
      isOwnersLoaded: false,
      isCardsLoaded: false,
      transactions: [],
      isTransactionsLoaded: false,
      apiEndpoint: 'https://loyalty.vendingtycoon.eu',
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      cards: [],
      cardTransactions: [],
      pagination: {
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 30,
      },
      name: '',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty TopUp Card', route: '' },
    ])

    axios.get(`${this.apiEndpoint}/api/v1/owners`).then(({ data }) => {
      this.owners = [...data['hydra:member']]
      this.isOwnersLoaded = true
    })
  },
  watch: {
    '$route.query.page'(newPage) {
      this.pagination.currentPage = newPage || 1
      this.getCards(this.name)
    },
  },
  methods: {
    onOwnerSelect(name) {
      this.cards = []
      this.isCardsLoaded = false
      this.name = name
      this.getCards(name)
      this.range.start = null
      this.range.end = null
      this.isTransactionsLoaded = false
    },
    getCards(name) {
      axios
        .get(
          `${this.apiEndpoint}/api/v1/cards?owner.name=${name}&properties[]=serialNumber&properties[]=userFullName&order[createdAt]=desc&page=${this.pagination.currentPage}&itemsPerPage=${this.pagination.itemsPerPage}`
        )
        .then(({ data }) => {
          this.cards = [...data['hydra:member']]
          this.isCardsLoaded = true
          this.pagination.totalItems = data['hydra:totalItems']
          this.pagination.totalPages = Math.ceil(
            this.pagination.totalItems / this.pagination.itemsPerPage
          )
        })
    },
    onRangeSelect() {
      this.transactions = []
      this.isTransactionsLoaded = false

      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      const cardSerialNumbers = this.cards.map((card) => card.serialNumber)

      ApiService.post(`cards?after=${after}&before=${before}`, {
        cards: cardSerialNumbers,
      }).then(({ data }) => {
        data.map((card) => {
          const index = this.cards.findIndex(
            (c) => c.serialNumber === card.cardSerial
          )

          this.cards[index]['transactions'] = card.transactions
          this.cards[index]['totalAmount'] = parseInt(card.totalAmount)
          this.cards[index]['totalBonus'] = card.totalBonus
        })
      })

      setTimeout(() => {
        this.isTransactionsLoaded = true
      }, 1000)
    },
    exportAsExcel() {
      const start = format(new Date(this.range.start), 'yyyy-MM-dd')
      const end = format(addDays(new Date(this.range.end), +1), 'yyyy-MM-dd')

      let temDepositsIds = ''
      this.cards.forEach((card) => {
        temDepositsIds += card.serialNumber + ','
      })

      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/owner-card-transactions/export/${temDepositsIds}/${start}/${end}`
        )
        .then(({ data }) => {
          if (data.status === 'ok') {
            window.location.replace(data.fileUrl)
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Export success',
            })
          }
        })
    },
    toggleCardsTransactionsModal(key) {
      this.cardTransactions = []
      this.cardTransactions = this.cards[key]['transactions']
      this.$root.$emit('bv::show::modal', 'card_transactions_modal')
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--owner-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
